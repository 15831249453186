var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-600 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300",staticStyle:{"background":"#fafafa"}},[_c('div',{staticClass:"tw-pl-4 sm:tw-pl-6 lg:tw-pl-8 tw-text-gray-800 tw-flex tw-items-center tw-justify-between tw-pt-1 tw-mb-1"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-neutral-500 tw-text-primary-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-arrow-rotate-right"},on:{"click":_vm.refresh}})],1)])]),_c('ui-data-table',{attrs:{"headers":_vm.headers,"isLoading":!!_vm.isLoading,"items":_vm.invoices,"noDataMessage":"There are no invoices to display.","hideable-columns":"","resizeable-columns":""},scopedSlots:_vm._u([{key:"td-date",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.date)+" ")])])]}},{key:"td-id",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.id)+" ")])])]}},{key:"td-type",fn:function(ref){return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v("System Generated")])])]}},{key:"td-number",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.number)+" ")])])]}},{key:"td-customer",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.customer)+" ")])])]}},{key:"td-status",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.status)+" ")])])]}},{key:"td-amounts",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},_vm._l((invoice.amounts),function(amount,i){return _c('div',{key:i,staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(amount)+" ")])}),0)]}},{key:"td-total",fn:function(ref){
var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.totalNotional)+" ")])])]}},{key:"td-actions",fn:function(ref){return [_c('td',{},[_c('div',{staticClass:"tw-inline-block tw-px-3"},[_c('ui-button',{nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('show-detail')}}},[_vm._v("View")])],1),_c('div',{staticClass:"tw-inline-block"},[_c('ui-button',[_vm._v("Load")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }