



































































































import axios from 'axios';
import Component from 'vue-class-component';

import { Contact, ReconciliationStatus, Transaction } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiDataTable,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class Invoices extends BaseVue {
  declare register?: any;

  // public get headers() {
  //   const s = Object.values(this.columns).map((x) => x.heading);
  //   return s;
  // }

  public get invoices() {
    return [
      {
        date: '12/15/2022',
        id: 'ad235jsd00n3321',
        number: '105',
        status: 'Pending',
        customer: 'Figment',
        amounts: ['CSPR 5301 ($0.02)'],
        totalNotional: '$106.02',
      },
    ];
  }

  readonly headers = [
    {
      id: 'date',
      label: 'Date',
      defaultVisibility: true,
    },
    {
      id: 'number',
      label: 'No',
      defaultVisibility: true,
    },
    {
      id: 'type',
      label: 'Type',
      defaultVisibility: true,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'customer',
      label: 'Customer',
      defaultVisibility: true,
    },
    {
      id: 'amounts',
      label: 'Amounts',
      defaultVisibility: true,
    },
    {
      id: 'total',
      label: 'Total',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;

  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get displayRegister() {
    console.log(this.register);
    return this.isLoading ? [] : (this.register?.items ?? []).filter(isDefined);
  }

  public async loadRegister() {
    if (this.$store.state.currentOrg && this.walletId !== 'select') {
      this.isLoading = 1;
      let currencyId;
      let hasError = false;
      if (this.ticker && this.ticker !== null) {
        const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${this.ticker}`;
        const resp = await axios.get(assetsApiUrl);
        if (resp === undefined || resp.status !== 200) {
          hasError = true;
          this.showErrorSnackbar(`Invalid ticker ${this.ticker}`);
        } else {
          currencyId = `COIN.${resp.data.coinId}`;
        }
      }
      if (!hasError) {
        const res = await this.$apollo.query({
          query: RegisterQuery,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            filter: {
              walletId: this.walletId,
              endDate: this.endDate,
              currencyId,
            },
          },
        });
        this.register = res.data.register;
      }

      this.isLoading = 0;
    }
  }

  public showMenuOn: Transaction | null = null;
  public expandedTxn: Transaction | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: Transaction | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
