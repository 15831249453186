












































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

import type { Contact, ContactAddress } from '@/api-svc-types';
import { Coins } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import { asDefined } from '@/utils/guards';

@Component({
  components: { UiDataTable, UiDatePicker, UiButton, UiSelect },
})
export default class InvoiceDetails extends BaseVue {
  @Prop({ required: true })
  public readonly invoice!: any;

  public isLoading = false;

  public dueDate = new Date('05/31/2022').toISOString().substring(0, 10);
  public invoiceStartDate = new Date('05/01/2022').toISOString().substring(0, 10);
  public term = 'net 30';
  public invoiceNumber = '1003';
  public figment = 'Figment';

  readonly lines = [
    {
      date: '',
    },
  ];

  readonly headers = [
    {
      id: 'date',
      label: 'Date',
      defaultVisibility: true,
    },
    {
      id: 'token',
      label: 'Token',
      defaultVisibility: true,
    },
    {
      id: 'qty',
      label: 'Qty',
      defaultVisibility: true,
    },
    {
      id: 'price',
      label: 'Price',
      defaultVisibility: true,
    },
    {
      id: 'amount',
      label: 'Amount',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];
}
