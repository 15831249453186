var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300",staticStyle:{"background":"#fafafa"}},[_c('div',{staticClass:"tw-flex tw-justify-end tw-pr-6 tw-pt-2"},[_c('ui-button',{nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('cancel')}}},[_vm._v("Back to List")])],1),_c('div',{staticClass:"tw-text-gray-800 tw-flex tw-pt-1 tw-mb-1 tw-flex-col"},[_c('div',{staticClass:"tw-flex-grow"},[_vm._m(0),_c('div',{staticClass:"tw-w-full",staticStyle:{"background-color":"#e3effa"}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-p-4 tw-w-3/5"},[_c('div',{staticClass:"tw-pb-2"},[_c('ui-select',{staticClass:"tw-w-3/5 tw-bg-white tw-rounded-md",attrs:{"values":{
                Figment: 'Figment',
              },"value":_vm.figment}})],1),_c('div',{staticClass:"tw-flex tw-justify-between tw-flex-grow"},[_vm._m(1),_c('div',{staticClass:"tw-flex tw-flex-col tw-w-1/2 tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-grow tw-items-center"},[_c('span',[_vm._v("Invoice Number")]),_c('ui-select',{staticClass:"tw-w-1/2 tw-bg-white tw-rounded-md",attrs:{"values":{
                    '1003': '1003',
                  },"value":_vm.invoiceNumber}})],1),_c('div',{staticClass:"tw-flex tw-justify-between tw-w-grow tw-items-center"},[_c('span',[_vm._v("Terms")]),_c('ui-select',{staticClass:"tw-w-1/2 tw-bg-white tw-rounded-md",attrs:{"values":{
                    'net 30': 'net 30',
                  },"value":_vm.term}})],1),_c('div',{staticClass:"tw-flex tw-justify-between tw-w-grow tw-items-center"},[_c('span',[_vm._v("Invoice startDate")]),_c('ui-date-picker',{staticClass:"tw-w-1/2",attrs:{"value":_vm.invoiceStartDate}})],1),_c('div',{staticClass:"tw-flex tw-justify-between tw-w-grow tw-items-center"},[_c('span',[_vm._v("Due Date")]),_c('ui-date-picker',{staticClass:"tw-w-1/2",attrs:{"value":_vm.dueDate}})],1)])]),_vm._m(2)])])])]),_c('div',{staticClass:"tw-text-gray-800 tw-flex tw-pt-1 tw-mb-1"}),_c('ui-data-table',{attrs:{"headers":_vm.headers,"isLoading":!!_vm.isLoading,"items":_vm.lines,"noDataMessage":"There are no invoices to display.","hideable-columns":"","resizeable-columns":""},scopedSlots:_vm._u([{key:"td-date",fn:function(ref){
                  var invoice = ref.item;
return [_c('td',{},[_c('ui-date-picker',{attrs:{"value":invoice.date}})],1)]}},{key:"td-token",fn:function(ref){
                  var invoice = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(invoice.date)+" ")])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-flex-grow"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-flex-grow tw-items-center"},[_c('div',{staticClass:"tw-pb-2 tw-pt-2 tw-pl-4 tw-text-4xl tw-font-bold",staticStyle:{"color":"#3498db"}},[_vm._v("INVOICE")])]),_c('div',{staticClass:"tw-w-2/3 tw-p-4"},[_c('div',{staticClass:"tw-flex tw-flex-grow"},[_c('span',{staticClass:"tw-w-2/3 tw-font-bold"},[_vm._v("BitAlpha, inc")]),_c('span',{staticClass:"tw-flex-grow tw-text-left"},[_vm._v("info@bitwave.io")])]),_c('div',{staticClass:"tw-flex tw-flex-grow"},[_c('span',{staticClass:"tw-w-2/3"},[_vm._v("60 Russell St")]),_c('span',{staticClass:"tw-flex-grow tw-text-left"},[_vm._v("bitwave.io")])]),_c('div',[_c('span',[_vm._v("San Fransisco CA 94109")])]),_c('div',{staticClass:"tw-py-4"},[_c('a',{staticStyle:{"color":"#3498db"}},[_vm._v("Edit Company")])])])]),_c('div',[_c('img',{staticClass:"tw-h-12 tw-mr-6 tw-mt-6",attrs:{"src":"logo.png"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"tw-font-bold"},[_vm._v("bill to:")]),_c('span',[_vm._v(" Figment")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-py-2"},[_c('a',{staticStyle:{"color":"#3498db"}},[_vm._v("Edit Customer")])])}]

export { render, staticRenderFns }